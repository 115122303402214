import { HiMenu } from "react-icons/hi";
import { useState } from "react";
import { Link } from "react-router-dom";

const HeaderNav = ({ sidebarClick }) => {
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  return (
    <header className="w-full h-10 flex items-center justify-between my-2">
      <div className="block  ml-8">
        <button
          className="flex p-2 items-center rounded-full bg-white shadow text-gray-900 border border-gray-900 text-md"
          onClick={sidebarClick}
        >
          <HiMenu size={25} />
        </button>
      </div>
      <div className="text-xs p-4 max-w-sm">copywritingrobotapp@gmail.com</div>
      {/* <div className="hidden md:block relative flex flex-col  h-full px-3 md:w-full mt-2">
        <div className="relative p-1 flex items-center w-full space-x-4 justify-end">
          <div className="relative inline-block text-left ">
            <div>
              <button
                type="button"
                className=" border border-white bg-white shadow-sm flex items-center justify-center w-full rounded-md  px-4 py-2 text-sm font-medium text-gray-200 dark:text-gray-50 hover:bg-gray-750 dark:hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-gray-500"
                onClick={handleClick}
              >
                Account
                <svg
                  width="20"
                  height="20"
                  fill="currentColor"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1408 704q0 26-19 45l-448 448q-19 19-45 19t-45-19l-448-448q-19-19-19-45t19-45 45-19h896q26 0 45 19t19 45z"></path>
                </svg>
              </button>
            </div>
            {isClicked && (
              <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white border border-white   ring-1 ring-black ring-opacity-5">
                <div
                  className="py-1 "
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="options-menu"
                >
                  <Link
                    className="block block px-4 py-2 hover:text-gray-300 text-md text-gray-200   flex flex-col"
                    role="menuitem"
                    to="/dashboard/account"
                  >
                    Account Settings
                  </Link>
                  <Link
                    className="block block px-4 py-2 hover:text-gray-300 text-md text-gray-200   flex flex-col"
                    role="menuitem"
                    to="/auth/logout"
                  >
                    Logout
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </div> */}
    </header>
  );
};

export default HeaderNav;
