import authAxios from "./axios";
const axios = authAxios();

// const createSingleProductDescription = async (data) => {
//   let res;
//   try {
//     res = await axios({
//       method: "POST",
//       url: `${process.env.REACT_APP_SERVER_URL}/api/v1/createSingleDescriptionRedisJob`,
//       data: {
//         data,
//       },
//     });

//     const jobId = res.data.id;

//     let finalResult;

//     const checkDataRedis = async (id) => {
//       let redisResult;
//       try {
//         redisResult = await axios({
//           method: "POST",
//           url: `${process.env.REACT_APP_SERVER_URL}/api/v1/job/${id}`,
//           data: {
//             type: "singleDescription",
//           },
//         });
//       } catch (err) {
//         console.log("error", err.response.data.message);
//       }

//       if (
//         redisResult.data.state === "completed" &&
//         redisResult.data.data !== null
//       ) {
//         finalResult = redisResult.data;
//       } else if (redisResult.data.state === "failed") {
//         throw new Error();
//       } else {
//         await new Promise((resolve) => setTimeout(() => resolve(), 1000));
//         await checkDataRedis(id);
//       }
//     };

//     await checkDataRedis(jobId);

//     if (finalResult) {
//       return finalResult.data;
//     }
//   } catch (err) {
//     console.log(err);
//   }
// };

const createSingleProductDescription = async (data) => {
  try {
    const res = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/createSingleProductDescription`,
      data,
    });

    if (res && res.data && res.data.data) {
      return res.data.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export default createSingleProductDescription;
