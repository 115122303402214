import PricingCards from "../../Homepage/components/pricingCards";
import PricingCardsMonthly from "../../Homepage/components/pricingCardsMonthly";
import Countdown from "react-countdown";
import FAQ from "../../Homepage/components/FAQ";

const CountdownTimerComp = ({ days, hours, minutes, seconds }) => {
  return (
    <div className="grid grid-cols-4 gap-2 p-2">
      <div class="flex flex-col items-center px-4 border border-gray-300 shadow-md rounded-lg p-1 bg-gray-800 text-white">
        <span class="text-2xl  font-bold">{days}</span>
        <span class=" mt-2 text-sm">Day </span>
      </div>
      <div class=" flex flex-col items-center px-4 border border-gray-300 shadow-md rounded-lg p-1 bg-gray-800 text-white">
        <span class="text-2xl  font-bold">{hours}</span>
        <span class=" mt-2 text-sm">Hours </span>
      </div>
      <div class=" flex flex-col items-center px-4 border border-gray-300 shadow-md rounded-lg p-1 bg-gray-800 text-white">
        <span class="text-2xl  font-bold">{minutes}</span>
        <span class=" mt-2 text-sm">Minutes </span>
      </div>
      <div class=" flex flex-col items-center px-4 border border-gray-300 shadow-md rounded-lg p-1 bg-gray-800 text-white">
        <span class="text-2xl  font-bold">{seconds}</span>
        <span class=" mt-2 text-sm">Seconds </span>
      </div>
    </div>
  );
};

const ChoosePlan = () => {
  return (
    <div className=" font-sans bg-white relative overflow-auto h-screen">
      <div className="flex flex-col items-center  px-10 my-auto ">
        {/* <div className=" rounded-xl text-gray-900 max-w-4xl w-full mx-auto text-center m-5">
          <div className="text-2xl font-bold "> Discount Guaranteed For</div>
          <div className=" max-w-xl mx-auto ">
            <Countdown
              date={Date.now() + 242320000}
              renderer={CountdownTimerComp}
            />
          </div>
        </div> */}
        {/* <div className="uppercase text-gray-200 dark:text-gray-200 font-black text-3xl flex items-center h-24 mr-4 ">
          <img
            className="h-36 mx-auto"
            src="/logo.png"
            alt="Copywriting Robot"
          />
        </div> */}
        <div className="my-auto align-middle mt-10">
          <div className="text-center text-4xl font-bold text-gray-900 mt-10  ">
            AI Copywriting Without The{" "}
            <span className="text-red-500">$500</span> Yearly Fees.
          </div>
          <PricingCards
            freeLink="https://copywritingrobot.com/dashboard/welcomeSettings"
            starterLink="https://buy.stripe.com/fZe7tE8ro8JM0PS4gp"
            standardLink="https://buy.stripe.com/aEU8xIbDA9NQgOQ6oD"
            businessLink="https://buy.stripe.com/dR64hscHEd02aqs7sT"
            showFree={false}
          />
          {/* <PricingCardsMonthly
            freeLink="https://copywritingrobot.com/dashboard/welcomeSettings"
            starterLink="https://buy.stripe.com/9AQg0a5fcbVY1TWaF2"
            standardLink="https://buy.stripe.com/4gwg0a4b84tw5688wV"
            businessLink="https://buy.stripe.com/aEU8xIbDA9NQgOQ6oD"
          /> */}
        </div>
        <hr className="w-48 h-1 mx-auto bg-white border-0 rounded my-20 dark:bg-gray-300"></hr>
        <div className="border-1 shadow-xl rounded-xl my-4 px-6 py-4 max-w-4xl w-full mx-auto bg-gray-800 text-white mt-5">
          <h2 className="text-2xl font-bold tracking-wide  flex justify-center mb-5 text-center">
            💰 60-Day Closing Guarantee
          </h2>
          <p className="max-w-3xl mx-auto text-center">
            E-commerce can be a tough market especially if you have a new store.
            That's why our app is backed by this guarantee. If your store closes
            for any reason in the next 60 days we’ll issue you a full refund.
          </p>
        </div>
        {/* <div className="mt-10 bg-white rounded-xl">
          <div className=" grid grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto ">
            <div className=" max-w-2xl   px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
              <div className="">
                I only have a few hundred products so I didn’t think I would use
                it much.
                <p className="mt-4">
                  However I was surprised on how easy it made writing new
                  descriptions.
                </p>
                <p className="mt-4 font-semibold">- Sean, Founder</p>
              </div>
            </div>
            <div className=" text-gray-900 max-w-2xl  rounded-xl  mx-aut px-6 py-4 mt-4  ">
              <div>
                I used to just leave the Aliexpress product descriptions because
                I figured that if works there it should work on my store.
                <p className="mt-4">
                  But I didn’t realize how much more professional my store
                  looked after with proper product descriptions.
                </p>
                <p className="mt-4"></p>
                <p className="mt-4 font-semibold">
                  - Ainslee, Shopify Merchant
                </p>
              </div>
            </div>
            <div className=" text-gray-900 max-w-2xl   px-6 py-4 mt-4 align-middle flex flex-col justify-center items-center">
              <div className="">
                I consider myself a decent copywriter.
                <p className="mt-4">
                  This AI did a decent job though. Now it’s part of my product
                  uploading process.
                </p>
                <p className="mt-4 font-semibold">Bernard - Dropshipper</p>
              </div>
            </div>
          </div>
        </div> */}
        <FAQ />
        {/* <div class=" mt-10 flex justify-center items-center  ">
          <div class="">
            <div class="text-center font-bold ">
              <div className="text-center text-3xl font-bold text-gray-900 ">
                What Languages are Included?
              </div>
              <div className="px-10 py-2 rounded-xl shadow-xl mt-10 max-w-2xl text-gray-800 text-lg border-2">
                <div className="grid gap-4 grid-cols-4  mt-2 ">
                  <div className="">
                    <ul className="space-y-2">
                      <li>🇬🇧🇺🇸 English </li>
                      <li>🇫🇷 French</li>
                      <li>🇩🇪 German</li>
                    </ul>
                  </div>
                  <div className="">
                    <ul className="space-y-2">
                      <li>🇩🇰 Danish</li>
                      <li>🇳🇱 Dutch</li>
                      <li>🇫🇮 Finish</li>
                    </ul>
                  </div>

                  <div>
                    <ul className="space-y-2">
                      <li>🇮🇹 Italian</li>
                      <li>🇵🇱 Polish</li>
                      <li>🇵🇹 Portuguese</li>
                    </ul>
                  </div>
                  <div className="">
                    <ul className="space-y-2">
                      <li>🇸🇰 Slovenian</li>
                      <li>🇪🇸 Spanish</li>
                    </ul>
                  </div>
                </div>
                <div className="text-center mt-4"></div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ChoosePlan;
